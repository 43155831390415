div.bandcamp-embeds {
    display: flex;
    flex-wrap: wrap;
    // justify-content: left;
   
    iframe {
        border: 0;
        width: calc(var(--content-width) * 0.45);
        height: calc(var(--content-width) * 0.45);
        margin: calc(var(--content-width) * 0.005);
    }
}